import { useEffect, useState } from 'react';
import { Modal, Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import '../styles.css'

const EmailModal = (props) => {
    const {
        isOpen,
        setShowEmailModal,
        handleEmailReports,
        sendingEmail,
        emailList,
        checkedEmailList,
        handleEmailChecked,
        handleAddEmailToList,
        handleDeleteEmailFromList,
        backupEmailMessage,
        setBackupEmailMessage
    } = props;

    const [emailInModal, setEmailInModal] = useState("")
    const [emailMessageInModal, setEmailMessageInModal] = useState("")

    const handleSetEmailInModal = (e) => {
        const email = e.target.value;
        setEmailInModal(email);
    }
    const handleClearEmailInModal = () => {
        setEmailInModal("")
    }

    const handleSetEmailMessageInModal = (e) => {
        const message = e.target.value;
        setEmailMessageInModal(message)
    }

    const handleOnHide = () => {
        setShowEmailModal(false)
        setEmailInModal("");
        setEmailMessageInModal("")
        setBackupEmailMessage("")
    }

    const isChecked = (id) => {
        return (checkedEmailList.indexOf(id) > -1);
    }

    useEffect(() => {
        if(backupEmailMessage !== "" && emailMessageInModal === "" ){
            setEmailMessageInModal(backupEmailMessage)
        }
    }, [backupEmailMessage, emailMessageInModal])

    return (
        <>
            <link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isOpen}
                onHide={handleOnHide}
            >
                <div className='d-none d-lg-block'>
                    <h4 style={{ marginTop: "1vh", marginLeft: "1vw" }}>Mail To:</h4>
                    <Modal.Body>
                        <Row className="email-box-row">
                            {emailList.map((addr, index) =>
                                addr.email !== '' &&
                                <Row className="email-address-row" style={{ textAlign: 'left', margin: 0 }} key={index}>
                                    <Col className="email-col" sm={1}>
                                        {!isChecked(addr.email) &&
                                            <span className="material-symbols-outlined"
                                                style={{
                                                    color: "black",
                                                    padding: "0",
                                                    margin: "0"
                                                }}
                                                id={'checkBox' + index}
                                                onClick={() => handleEmailChecked(addr.email)}>
                                                check_box_outline_blank
                                            </span>
                                        }
                                        {isChecked(addr.email) &&
                                            <span className="material-symbols-outlined"
                                                style={{
                                                    color: "black",
                                                    padding: "0",
                                                    margin: "0",
                                                }}
                                                id={'checkBox' + index}
                                                onClick={() => handleEmailChecked(addr.email)}>
                                                check_box
                                            </span>
                                        }
                                    </Col>
                                    <Col className="address-col email-col" >
                                        {addr.email}
                                    </Col>
                                    <Col className="email-col" style={{ textAlign: 'right' }}>
                                        <Button
                                            className="delete-btn"
                                            onClick={() => handleDeleteEmailFromList(addr)}
                                        >
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Row>
                        <Row>
                            <Col >
                                <Form.Control
                                    type="input"
                                    as="input"
                                    placeholder="New Email Address"
                                    value={emailInModal}
                                    onChange={(e) => { handleSetEmailInModal(e) }}
                                />
                            </Col>
                            <Col sm={3} style={{ textAlign: "right" }}>
                                <Button
                                    style={{ width: "100%" }}
                                    onClick={(e) => { handleAddEmailToList(emailInModal); handleClearEmailInModal(); }}
                                >
                                    Add
                                </Button>

                            </Col>
                        </Row>
                        <Row className='message-box-row'>
                            <Col>
                                <Form.Control
                                    as='textarea'
                                    placeholder="Type Message Here..."
                                    className="message-box"
                                    value={emailMessageInModal}
                                    onChange={(e) => { handleSetEmailMessageInModal(e) }}
                                />
                            </Col>
                            <Col sm={3} className="email-button-col">

                                &nbsp;&nbsp;&nbsp;
                                {sendingEmail &&
                                    <Button className='submit-btn'>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ height: "10px", width: "10px", marginBottom: "2px" }}
                                        />
                                    </Button>
                                }
                                {!sendingEmail &&
                                    <Button className='submit-btn' onClick={(e) => { handleEmailReports(emailMessageInModal); setEmailMessageInModal(""); }}>Send</Button>
                                }
                                <Button variant="outline-dark" className="cancel-btn" onClick={handleOnHide}>Cancel</Button>
                            </Col>
                        </Row>

                    </Modal.Body>
                </div>

                <div className='d-lg-none'>
                    <h4 style={{ marginTop: "1vh", marginLeft: "1vw" }}>Mail To:</h4>
                    <Modal.Body>
                        <Row className="email-box-row">
                            {emailList.map((addr, index) =>
                                addr.email !== '' &&
                                <Row className="email-address-row-small" style={{ textAlign: 'left', margin: 0 }} key={index}>
                                    <Col className="email-col">
                                        {!isChecked(addr.email) &&
                                            <span className="material-symbols-outlined"
                                                style={{
                                                    color: "black",
                                                    padding: "0",
                                                    margin: "0"
                                                }}
                                                id={'checkBox' + index}
                                                onClick={() => handleEmailChecked(addr.email)}>
                                                check_box_outline_blank
                                            </span>
                                        }
                                        {isChecked(addr.email) &&
                                            <span className="material-symbols-outlined"
                                                style={{
                                                    color: "black",
                                                    padding: "0",
                                                    margin: "0",
                                                }}
                                                id={'checkBox' + index}
                                                onClick={() => handleEmailChecked(addr.email)}>
                                                check_box
                                            </span>
                                        }
                                    </Col>
                                    <Col className="address-col email-col" >
                                        <span style={{ fontSize: "12px" }}>{addr.email}</span>
                                    </Col>
                                    <Col className="email-col" style={{ textAlign: 'right' }}>
                                        <Button
                                            className="delete-btn-sm"
                                            onClick={() => handleDeleteEmailFromList(addr)}
                                        >
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Row>
                        <Row>
                            <Col xs={9} s={9} md={9} style={{ paddingRight: "0px", marginRight: "0px" }}>
                                <Form.Control
                                    type="input"
                                    as="input"
                                    placeholder="New Email Address"
                                    value={emailInModal}
                                    onChange={(e) => { handleSetEmailInModal(e) }}
                                    style={{ fontSize: "12px", height: "100%", width: "100%" }}
                                />
                            </Col>
                            <Col xs={3} s={3} md={3} style={{ textAlign: "right", paddingLeft: "0.5px", marginLeft: "0px" }}>
                                <Button
                                    style={{ width: "100%" }}
                                    onClick={(e) => { handleAddEmailToList(emailInModal); handleClearEmailInModal(); }}
                                >
                                    Add
                                </Button>

                            </Col>
                        </Row>
                        <Row className='message-box-row-small'>
                            <Col>
                                <Form.Control
                                    as='textarea'
                                    placeholder="Type Message Here..."
                                    className="message-box"
                                    value={emailMessageInModal}
                                    onChange={(e) => { handleSetEmailMessageInModal(e) }}
                                    style={{ fontSize: "12px" }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} s={6} md={6} style={{ paddingRight: "0.5px", marginRight: "0px" }}>
                                {sendingEmail &&
                                    <Button className='submit-btn-small'>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ height: "10px", width: "10px", marginBottom: "2px" }}
                                        />
                                    </Button>
                                }
                                {!sendingEmail &&
                                    <Button className='submit-btn-small' onClick={(e) => { handleEmailReports(emailMessageInModal); setEmailMessageInModal("");}}>Send</Button>
                                }
                            </Col>
                            <Col xs={6} s={6} md={6} style={{ paddingLeft: "0.5px", marginLeft: "0px" }}>
                                <Button variant="outline-dark" className="cancel-btn-small" onClick={handleOnHide}>Cancel</Button>
                            </Col>
                        </Row>

                    </Modal.Body>
                </div>
            </Modal>
        </>
    )
}

export default EmailModal;