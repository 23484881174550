import React from 'react'
import { Row, Col, Dropdown, Button, Spinner } from 'react-bootstrap';
import './styles.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Code } from 'react-feather';

<link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@48,400,0,0" />

//Removed Water and Manure options (included in Environmentals) 8/29/22
const sampleTypes = [
    "Environmental",
    "Feed",
    "Fertilizer",
    "Plant",
    "Soil"
];
const timeRangeOptions = [
    "Current Month",
    "Previous Month",
    "3 Months",
    "6 Months",
    "Current Year",
    "12 Months",
    "2 Years",
    "3 Years",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000"
];

const Filters = (props) => {
    const {
        customerData,
        reportsQueryRequests,
        handleAccount,
        handleSampleType,
        handleTimeSelect,
        handleSetNewStartDate,
        handleSetNewEndtDate,
        handleFilterForProducer,
        handleFilterForMaterialType,
        selectedMaterialType,
        selectedProducer,
        advFiltersData,
        showAdvFilters,
        handleApplyFilters,
        gettingListData
    } = props;


    const accountCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="custom-toggle-button"
        >
            {reportsQueryRequests.account === 'All Accounts' &&
                <span className='toggle-label'>All Accounts</span>
            }
            {reportsQueryRequests.account !== 'All Accounts' &&
                <span className='toggle-label'>{reportsQueryRequests.account}</span>
            }
            <Code className="toggle-icon" size={15} />
            {children}
        </Button>
    ));

    const sampleTypesCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="custom-toggle-button"
        >
            <span className='toggle-label'>{reportsQueryRequests.sampleType}</span>
            <Code className="toggle-icon" size={15} />
            {children}
        </Button>
    ));

    const timeRangeCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="custom-toggle-button"
        >
            <span className='toggle-label'>{reportsQueryRequests.presetTimeOption}</span>
            <Code className="toggle-icon" size={15} />
            {children}
        </Button>
    ));

    const materialTypesCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="custom-toggle-button"
        >
            {selectedMaterialType !== "" && reportsQueryRequests.sampleType !== 'Soil' &&
                <span className='toggle-label'>{selectedMaterialType}</span>
            }
            {selectedMaterialType === "" && reportsQueryRequests.sampleType !== 'Soil' &&
                <span className='toggle-label'>All Sample Material Types</span>
            }
            {reportsQueryRequests.sampleType === 'Soil' &&
                <span className='toggle-label'>No Sample Material Types</span>
            }

            <Code className="toggle-icon" size={15} />
            {children}
        </Button>
    ));

    const producerCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="custom-toggle-button"
        >
            {selectedProducer !== "" &&
                <span className='toggle-label'>{selectedProducer}</span>
            }
            {selectedProducer === "" &&
                <span className='toggle-label'>All Producers</span>
            }
            <Code className="toggle-icon" size={15} />
            {children}
        </Button>
    ));

    const disabledProducerCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
            }}
            className="custom-toggle-button"
        >
            <span className='toggle-label'>Getting All Producers&nbsp;
                <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
                <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
                <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
            </span>
            <Code className="toggle-icon" size={15} />
        </Button>
    ));

    const disabledMaterialTypesCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
            }}
            className="custom-toggle-button"
        >
            <span className='toggle-label'>Getting All Material Types&nbsp;
                <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
                <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
                <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
            </span>
            <Code className="toggle-icon" size={15} />
        </Button>
    ));



    return (
        <>
            <Col xs={12} s={12} md={12} lg={12} xl={12}>
                {showAdvFilters &&
                    <>
                        <Row className='filters'>
                            <Col xs={12} s={12} md={12} lg={12} xl={12} className='filters-cols'>
                                <Dropdown>
                                    <Dropdown.Toggle as={accountCustomToggle}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "100%", height: "195px", overflowY: "scroll", fontSize: "10pt" }}>
                                        <Dropdown.Item value="All Accounts" onClick={handleAccount}>All Accounts</Dropdown.Item>
                                        {customerData.accounts.map((m, index) =>
                                            <Dropdown.Item
                                                value={m}
                                                key={index}
                                                onClick={handleAccount}
                                            >
                                                {m}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ order: 1, span: 12 }} s={{ order: 1, span: 12 }} md={{ order: 1, span: 12 }} l={{ order: 1, span: 12 }} xl={{ order: 1, span: 12 }} className='filters-cols'>
                                <Dropdown>
                                    <Dropdown.Toggle as={timeRangeCustomToggle}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "100%", height: "175px", overflowY: "scroll", fontSize: "10pt" }}>
                                        {timeRangeOptions.map((m, index) =>
                                            <Dropdown.Item
                                                value={m}
                                                key={index}
                                                onClick={(e) => { handleTimeSelect(e) }}
                                            >
                                                {m}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xs={{ order: 2, span: 6 }} s={{ order: 2, span: 6 }} md={{ order: 2, span: 6 }} lg={{ order: 2, span: 6 }} xl={{ order: 2, span: 6 }} className='filters-cols'>
                                <DatePicker
                                    selected={advFiltersData.startDateForAdvFilters}
                                    size="sm"
                                    className='date-picker'
                                    onChange={date => handleSetNewStartDate(date)}

                                />
                            </Col>
                            <Col xs={{ order: 3, span: 6 }} s={{ order: 3, span: 6 }} md={{ order: 3, span: 6 }} lg={{ order: 3, span: 6 }} xl={{ order: 3, span: 6 }} className='filters-cols'>
                                <DatePicker
                                    selected={advFiltersData.endDateForAdvFilters}
                                    size="sm"
                                    className='date-picker'
                                    onChange={date => handleSetNewEndtDate(date)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} s={12} md={12} lg={12} xl={12} className='filters-cols'>
                                <Dropdown>
                                    <Dropdown.Toggle as={sampleTypesCustomToggle}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: "100%", height: "115px", overflowY: "scroll", fontSize: "10pt" }}>
                                        {sampleTypes.map((m, index) =>
                                            <Dropdown.Item
                                                value={m}
                                                key={index}
                                                onClick={handleSampleType}
                                            >
                                                {m}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} s={12} md={12} lg={12} xl={12} className='filters-cols'>
                                {!gettingListData &&
                                    <Dropdown drop="up">
                                        <Dropdown.Toggle as={producerCustomToggle}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ width: "100%", height: "125px", overflowY: "scroll", fontSize: "10pt" }}>
                                            <Dropdown.Item value="" onClick={handleFilterForProducer}>All Producers</Dropdown.Item>
                                            {customerData.producers.map((m, index) =>
                                                <Dropdown.Item
                                                    value={m}
                                                    key={index}
                                                    onClick={handleFilterForProducer}
                                                >
                                                    {m}
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                {gettingListData &&
                                    <Dropdown>
                                        <Dropdown.Toggle as={disabledProducerCustomToggle}>
                                        </Dropdown.Toggle>
                                    </Dropdown>
                                }
                            </Col>
                            <Col xs={12} s={12} md={12} lg={12} xl={12} className='filters-cols'>
                                {reportsQueryRequests.sampleType !== 'Soil' && !gettingListData &&
                                    <Dropdown drop="up">
                                        <Dropdown.Toggle as={materialTypesCustomToggle}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ width: "100%", height: "155px", overflowY: "scroll", fontSize: "10pt" }}>
                                            <Dropdown.Item value="" onClick={handleFilterForMaterialType}>All Sample Material Types</Dropdown.Item>
                                            {customerData.materialTypes.map((m, index) =>
                                                <Dropdown.Item
                                                    value={m}
                                                    key={index}
                                                    onClick={handleFilterForMaterialType}
                                                >
                                                    {m}
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                {reportsQueryRequests.sampleType !== 'Soil' && gettingListData &&
                                    <Dropdown drop="up">
                                        <Dropdown.Toggle as={disabledMaterialTypesCustomToggle}>
                                        </Dropdown.Toggle>
                                    </Dropdown>
                                }
                                {reportsQueryRequests.sampleType === 'Soil' &&
                                    <Dropdown drop="up">
                                        <Dropdown.Toggle as={materialTypesCustomToggle}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ width: "100%", height: "155px", overflowY: "scroll", fontSize: "10pt" }}>
                                            <Dropdown.Item value="" onClick={handleFilterForMaterialType}>No Sample Material Types</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} s={12} md={12} lg={12} xl={12} className='filters-cols'>
                                <Button style={{ width: "100%", height: "100%", paddingTop: "0" }} onClick={handleApplyFilters}>
                                    Apply Filters
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            </Col>
        </>
    )
}
export default Filters;