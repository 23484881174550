import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.css'

import { Row, Col } from 'react-bootstrap';

import ReportsArea from './ReportsArea';
import ActionButtons from './ActionButtons';
import Header from './Header'

const Reports = (props) => {
    const {
        customerData,
        cardData,
        handleCardsChecked,
        checkedCardsIDs,
        handleSetSelectedCard,
        selectedCard,
        reportsQueryRequests,
        handleAccount,
        handleSampleType,
        handleTimeSelect,
        handleSetNewStartDate,
        handleSetNewEndtDate,
        getDateFromEpoch,
        printRef,
        pdfsToEmail,
        handleShowEmailModal,
        handlePrint,
        handleNoReportsSelected,
        handleTooManyReportsSelected,
        handleFilterForProducer,
        handleFilterForMaterialType,
        advFiltersData,
        showAdvFilters,
        handleShowAdvancedFilters,
        handleApplyFilters,
        gettingCardData,
        setShowAdvFilters,
        show,
        handleClose,
        handleShow,
        gettingListData,
        handleUpdatReportDataForCard,
        handleSelectExportType,
        sampleTypeForPlaceholder,
        hanldeSelectAllCards,
        handleDeselectAllCards,
        samplesToExport,
        removePdfs
    } = props

    return (
        <div style={{ height: "100vh" }}>
            <Header />
            <Row className='reports-area'>
                <ReportsArea
                    customerData={customerData}
                    cardData={cardData}
                    handleCardsChecked={handleCardsChecked}
                    checkedCardsIDs={checkedCardsIDs}
                    handleSetSelectedCard={handleSetSelectedCard}
                    selectedCard={selectedCard}
                    reportsQueryRequests={reportsQueryRequests}
                    handleAccount={handleAccount}
                    handleSampleType={handleSampleType}
                    handleTimeSelect={handleTimeSelect}
                    handleSetNewStartDate={handleSetNewStartDate}
                    handleSetNewEndtDate={handleSetNewEndtDate}
                    getDateFromEpoch={getDateFromEpoch}
                    printRef={printRef}
                    pdfsToEmail={pdfsToEmail}
                    handleFilterForProducer={handleFilterForProducer}
                    handleFilterForMaterialType={handleFilterForMaterialType}
                    advFiltersData={advFiltersData}
                    showAdvFilters={showAdvFilters}
                    sampleTypeForPlaceholder={sampleTypeForPlaceholder}
                    handleShowAdvancedFilters={handleShowAdvancedFilters}
                    handleApplyFilters={handleApplyFilters}
                    gettingCardData={gettingCardData}
                    setShowAdvFilters={setShowAdvFilters}
                    show={show}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    gettingListData={gettingListData}
                    handleUpdatReportDataForCard={handleUpdatReportDataForCard}
                    hanldeSelectAllCards={hanldeSelectAllCards}
                    handleDeselectAllCards={handleDeselectAllCards}
                    removePdfs={removePdfs}
                        />
            </Row>
            <Row className='action-btns-area' >
                <Col xs={12} s={12} md={12} lg={5} xl={3} className='btns laptop-width'>
                    <ActionButtons
                        handleShowEmailModal={handleShowEmailModal}
                        handlePrint={handlePrint}
                        handleNoReportsSelected={handleNoReportsSelected}
                        handleTooManyReportsSelected={handleTooManyReportsSelected}
                        pdfsToEmail={pdfsToEmail}
                        exportOption={customerData.asciiExportOptions}
                        handleSelectExportType={handleSelectExportType}
                        reportsQueryRequests={reportsQueryRequests}
                        samplesToExport={samplesToExport}
                        gettingCardData={gettingCardData}
                    />
                </Col>
            </Row>
        </div>
    )
}
export default Reports;