import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Dropdown, Spinner } from 'react-bootstrap';
import './styles.css'
import { Code } from 'react-feather';
import { PdfDownloadModal } from './Modals';



const ActionButtons = (props) => {
  const {
    handleShowEmailModal,
    handlePrint,
    handleNoReportsSelected,
    handleTooManyReportsSelected,
    pdfsToEmail,
    exportOption,
    handleSelectExportType,
    reportsQueryRequests,
    samplesToExport,
    gettingCardData
  } = props;


  const exportCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ height: "37px" }}
      className="custom-toggle-button"
    >
      {reportsQueryRequests.selectedExportType === "" &&
        <span className='toggle-label' style={{ marginTop: "5px" }}>Choose Export Type</span>
      }
      {reportsQueryRequests.selectedExportType !== "" &&
        <span className='toggle-label' style={{ marginTop: "5px" }}>{reportsQueryRequests.selectedExportType}</span>
      }
      <Code className="toggle-icon" style={{ marginTop: "8px" }} size={16} />
      {children}
    </Button>
  ));

  const disabledExportCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
      }}
      style={{ height: "37px" }}
      className="custom-toggle-button"
    >
      <span className='toggle-label'>Getting Export Options&nbsp;
        <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
        <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
        <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
      </span>
      <Code className="toggle-icon" size={15} />
    </Button>
  ));

  const [exportId, setExportId] = useState("");
  const [listOfSampleIds, setListOfSampleIds] = useState("");
  const [listOfLabNums, setListOfLabNums] = useState("");
  const [exportingFile, setExportingFile] = useState(false)

  const [showPdfModal, setShowPdfModal] = useState(false)

  const handleShowPDFModal = () => {
    setShowPdfModal(true)
  }

  const handleDownloadFile = async (e) => {
    try {
      let fileName = "";
      setExportingFile(true)
      await fetch('https://report-exports-node-bjudfyf5za-uc.a.run.app/exportData', {
      // await fetch("http://localhost:4000/exportData", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "exportID": JSON.stringify(exportId),
          "sampleIDList": listOfSampleIds.toString(),
          "sampleType": JSON.stringify(reportsQueryRequests.sampleType),
          "sampleRange": listOfLabNums.toString()
        })
      })
        .then(response => {
          const disposition = response.headers.get('Content-Disposition');
          fileName = disposition.replace("attachment; filename=",'')
          console.log(fileName);
          return response.blob()
        })
        .then(
          blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setExportingFile(false)
          }
        )
    } catch (err) {
      console.log(err);
      setExportingFile(false)
      handleTooManyReportsSelected(e, 0, 'export error')
    }
  }

  const handleMergedFile = async (e) => {
    try {
      setExportingFile(true)
      let newArr = [];
      pdfsToEmail.forEach((x, index) => {
        newArr.push(x.ReportId)
      })
      await fetch(`https://report-pdf-node-bjudfyf5za-uc.a.run.app/getMultipleReports/${newArr}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => response.blob()).then(
        blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'resultsExport';
          document.body.appendChild(a);
          a.click();
          a.remove();
          setExportingFile(false)
          setShowPdfModal(false)
        }
      )
    } catch (err) {
      console.log(err);
      setExportingFile(false)
      setShowPdfModal(false)
      handleTooManyReportsSelected(e, 0, 'export error')
    }
  }

  const handleZippedFolder = async (e) => {
    try {
      setExportingFile(true)
      let newArr = [];
      pdfsToEmail.forEach((x, index) => {
        newArr.push(x.ReportId)
      })
      await fetch('https://report-pdf-node-bjudfyf5za-uc.a.run.app/downloadPDF', {
        // await fetch('http://localhost:4000/downloadPDF', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "uniqueReportIDs": JSON.stringify(newArr)
        })
      }).then(response => response.blob()).then(
        blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'resultsExport';
          document.body.appendChild(a);
          a.click();
          a.remove();
          setExportingFile(false)
          setShowPdfModal(false)
        }
      )
    } catch (err) {
      console.log(err);
      setExportingFile(false)
      setShowPdfModal(false)
      handleTooManyReportsSelected(e, 0, 'export error')
    }
  }

  useEffect(() => {
    let newlist = [];
    let labNumList = []
    samplesToExport.forEach((x) => {
      if (x.uniqueSampleIDs !== '' && x.uniqueSampleIDs !== undefined) {
        newlist.push(x.uniqueSampleIDs);
        labNumList.push(x.LabNumber)
      }
    })
    if (newlist !== ['']) {
      setListOfSampleIds(newlist)
      setListOfLabNums(labNumList)
    } else {
      setListOfSampleIds("")
      setListOfLabNums("")
    }
    setExportId(reportsQueryRequests.selectedExportTypeId)

  }, [reportsQueryRequests, samplesToExport])
  return (
    <>


      <Row className="buttons-row" style={{ width: "100%", margin: "0", padding: "0" }}>

        <Col xs={6} s={6} md={6} lg={6} xl={6} className='action-cols'>
          {!gettingCardData &&
            <Dropdown drop="up">
              <Dropdown.Toggle as={exportCustomToggle}>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%", overflow: "scroll", maxHeight: "180px" }}>
                {exportOption.map((option, index) =>
                  <Dropdown.Item
                    value={option.label}
                    key={index}
                    onClick={(e) => { handleSelectExportType(option) }}
                  >
                    {option.label}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          }
          {gettingCardData &&
            <Dropdown>
              <Dropdown.Toggle as={disabledExportCustomToggle}>
              </Dropdown.Toggle>
            </Dropdown>
          }
        </Col>
        <Col xs={6} s={6} md={6} lg={2} xl={2} className='action-cols' >
          {(exportId !== "" && listOfSampleIds.length >= 1 && !exportingFile && exportId !== null) &&
            <Button
              className='action-btn'
              onClick={(e) => { handleDownloadFile(e) }}
            >
              Export
            </Button>
          }
          {(exportId === null && listOfSampleIds.length <= 20 && listOfSampleIds.length >= 1 && !exportingFile) &&
            <Button
              className='action-btn'
              onClick={(e) => { handleShowPDFModal() }}
            >
              Export
            </Button>
          }
          {(exportId === "" || listOfSampleIds.length < 1) && (!exportingFile) &&
            <Button
              className='action-btn btn-secondary'
              onClick={(e) => { handleTooManyReportsSelected(e, 0, 'export') }}
            >
              Export
            </Button>
          }
          {exportId === null && listOfSampleIds.length > 20 && !exportingFile &&
            <Button
              className='action-btn btn-secondary'
              onClick={(e) => { handleTooManyReportsSelected(e, 20, 'download') }}
            >
              Export
            </Button>
          }
          {exportingFile &&
            <Button
              className='action-btn'
            >
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                style={{ height: "20px", width: "20px" }}
              />
            </Button>
          }
        </Col>
        <Col xs={6} s={6} md={6} lg={2} xl={2} className='action-cols'>
          {(pdfsToEmail[0] && pdfsToEmail[0].ReportId !== "") && pdfsToEmail.length > 0 && pdfsToEmail.length <= 20 &&
            <Button
              className='action-btn'
              onClick={handlePrint}
            >
              Print
            </Button>
          }
          {(pdfsToEmail.length === 0 || pdfsToEmail === "" || (pdfsToEmail[0] && pdfsToEmail[0].ReportId === "")) &&
            <Button

              className='action-btn btn-secondary'
              onClick={handleNoReportsSelected}
            >
              Print
            </Button>
          }
          {(pdfsToEmail.length >= 21) &&
            <Button

              className='action-btn btn-secondary'
              onClick={(e) => { handleTooManyReportsSelected(e, 20, 'print') }}
            >
              Print
            </Button>
          }
        </Col>
        <Col xs={6} s={6} md={6} lg={2} xl={2} className='action-cols'>
          {(pdfsToEmail[0] && pdfsToEmail[0].ReportId !== "") && pdfsToEmail.length > 0 && pdfsToEmail.length <= 6 &&
            <Button
              className='action-btn'
              onClick={handleShowEmailModal}
            >
              Email
            </Button>
          }
          {(pdfsToEmail.length === 0 || pdfsToEmail === "" || (pdfsToEmail[0] && pdfsToEmail[0].ReportId === "")) &&
            <Button

              className='action-btn btn-secondary'
              onClick={handleNoReportsSelected}
            >
              Email
            </Button>
          }
          {(pdfsToEmail.length >= 7) &&
            <Button
              className='action-btn btn-secondary'
              onClick={(e) => { handleTooManyReportsSelected(e, 6, 'email') }}
            >
              Email
            </Button>
          }
        </Col>
      </Row>

      <PdfDownloadModal
        isOpen={showPdfModal}
        setShowPdfModal={setShowPdfModal}
        handleMergedFile={handleMergedFile}
        handleZippedFolder={handleZippedFolder}
        exportingFile={exportingFile}
      />
    </>
  )
}
export default ActionButtons;