import { Modal, Button, Row, Col, Spinner } from 'react-bootstrap';
import '../styles.css'

const PdfDownloadModal = (props) => {
    const {
        isOpen,
        setShowPdfModal,
        handleMergedFile,
        handleZippedFolder,
        exportingFile
    } = props;

    const handleOnHide = () => {
        setShowPdfModal(false)
    }

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isOpen}
                onHide={handleOnHide}
            >
                <Modal.Header closeButton />
                <Modal.Body style={{ textAlign: "center" }}>
                    How would you like to export the PDFs?
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "center" }}>
                    {!exportingFile &&
                        <Row style={{ margin: "0", padding: "0", width: "100%" }}>

                            <Col>
                                <Button
                                    style={{ width: "100%" }}
                                    onClick={(e) => { handleMergedFile(e) }}
                                >
                                    Single File
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    style={{ width: "100%" }}
                                    onClick={(e) => { handleZippedFolder(e) }}
                                >
                                    Zipped Folder
                                </Button>
                            </Col>
                        </Row>
                    }
                    {exportingFile &&
                        <span>
                            Downloading PDFs &nbsp;
                            <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
                            <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
                            <Spinner animation="grow" style={{ height: "7px", width: "7px" }} />
                        </span>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default PdfDownloadModal;