import { useEffect, useState } from 'react';
// import { useReactToPrint } from 'react-to-print';
import { Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.css'

import Reports from './ReportsApp';
import MobileReportsView from './MobileReportsView';
import { EmailModal, MessageModal } from './Modals';
import pdfs from './pdf.json'


// const reportsGraphql = 'http://localhost:4000/graphql';
const reportsGraphql = 'https://reports-gql-staging-bjudfyf5za-uc.a.run.app/graphql'
const emailGraphql = 'https://send-email-service-staging-bjudfyf5za-uc.a.run.app/graphql';
// const emailGraphql = 'http://localhost:4000/graphql';


function getDateFromEpoch(epoch) {
    if (epoch > 0) {
        const date = new Date(JSON.parse(epoch));
        const year = date.getFullYear();
        const twoDigitYear = year.toString().substr(-2);
        const month = JSON.stringify(date.getMonth() + 1);
        const day = date.getDate();
        const newDate = `${month.padStart(2, '0')}/${day}/${twoDigitYear}`;
        return newDate;
    } else {
        return "0/0/0000"
    }
}


const App = () => {
    const prevMonth = new Date().setMonth(new Date().getMonth() - 1);
    const today = new Date().getTime();

    // const printRef = useRef();
    const [customerData, setCustomerData] = useState({
        accounts: [],
        producers: [],
        materialTypes: [],
        asciiExportOptions: [{
            label: "",
            id: ""
        }]
    })
    const [reportsQueryRequests, setReportsQueryRequests] = useState({
        account: "All Accounts",
        sampleType: "Feed",
        startDate: prevMonth,
        endDate: today,
        producer: "",
        sampleMaterialType: "",
        userID: "",
        //Not used for gql queries:
        presetTimeOption: "Previous Month",
        selectedExportType: "",
        selectedExportTypeId: ""
    })
    const [cardData, setCardData] = useState([{
        LabNumber: "",
        SampleID: "",
        uniqueSampleIDs: "",
        Producer: "",
        MaterialType: "",
        Received: "",
        Reported: "",
        ReportType: "",
        Location: "",
        ReportId: "",
        AccountNumber: "",
        ExtraFields: [],
        AccountName: "",
        Reports: [{
            ReportType: "",
            ReportId: ""
        }],
        UniqueLabNumber: "",
        HeaderInfo: [{
            LabLetter: "",
            ReportTypeID: "",
            PaperType: ""
        }]
    }])
    const [gettingCardData, setGettingCardData] = useState(false)
    const [gettingListData, setGettingListData] = useState(false)

    const [advFiltersData, setAdvFiltersData] = useState({
        startDateForAdvFilters: prevMonth,
        endDateForAdvFilters: today,
        selectedProducer: "",
        selectedMaterialType: ""
    })
    const [showAdvFilters, setShowAdvFilters] = useState(false);

    const [checkedCardsIDs, setcheckedCardsIDss] = useState("");
    const [selectedCard, setSelectedCard] = useState("");
    const [pdfsToEmail, setPdfsToEmail] = useState([{
        ReportId: ""
    }]);
    const [samplesToExport, setSamplesToExport] = useState([{
        uniqueSampleIDs: "",
        LabNumber:""
    }])
    const [pdfAttachment, setPDFA] = useState([]);
    const [emailList, setEmailList] = useState([{
        email: "",
        emailId: ""
    }]);
    const [checkedEmailList, setCheckedEmailList] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [displayMessage, setDisplayMessage] = useState("");
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [sampleTypeForPlaceholder, setSampleTypeForPlaceholder] = useState('Feed');
    const [removePdfs, setRemovePdfs] = useState(false);
    const [backupEmailMessage, setBackupEmailMessage] = useState("");
    // const [printReporstUrl, setPrintReporstUrl] = useState('');

    // function gets a specific cookie
    function getCookie(cName) {
        const name = cName + "=";
        // const cDecoded = decodeURIComponent(document.cookie); //to be careful
        const cArr = document.cookie.split('; ');
        let res;
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
        })
        return res
    }

    const fetchData = async () => {
        setGettingCardData(true)

        // // will be used to get userName from cookie
        let userName;
        const neededCookie = await getCookie("username");
        console.log('neededCookie: ', neededCookie);
        userName = neededCookie;
        // const userName = 'Dacoma Coop';
        // const userName = 'JDHeiskell';
        // const userName = 'tonyb';

        if (userName !== "" && userName !== undefined) {
            const getAccountsQuery = `{accounts(userName:${JSON.stringify(userName)})}`;
            const getAccountsFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getAccountsQuery }), headers: { 'Content-Type': 'application/json' } });
            const getAccountsJSON = await getAccountsFetch.json();
            const getAccountsResponse = JSON.parse(getAccountsJSON.data.accounts)
            if (getAccountsResponse.length > 0) {
                const accountList = [];
                getAccountsResponse.forEach((x) => {
                    const forAccountList = `${x.AccountName} (${x.AccountID})`
                    accountList.push(forAccountList)
                })
                setCustomerData(prevState => {
                    return { ...prevState, accounts: accountList }
                })
            }
            const getUserIdQuery = `{getUser(userName:${JSON.stringify(userName)})}`;
            const getUserIdFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getUserIdQuery }), headers: { 'Content-Type': 'application/json' } });
            const getUserIdJSON = await getUserIdFetch.json();
            const getUserIdResponse = JSON.parse(getUserIdJSON.data.getUser)
            setReportsQueryRequests(prevState => {
                return { ...prevState, userID: JSON.stringify(getUserIdResponse[0].UserID) }
            })
            const getAsciiExportOptionsQuery = `{getAsciiExport(sampleTypeID:${JSON.stringify(reportsQueryRequests.sampleType)}, userID:${JSON.stringify(JSON.stringify(getUserIdResponse[0].UserID))})}`;
            const getAsciiExportOptionsFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getAsciiExportOptionsQuery }), headers: { 'Content-Type': 'application/json' } });
            const getAsciiExportOptionsJSON = await getAsciiExportOptionsFetch.json();
            const getAsciiExportOptionsResponse = JSON.parse(getAsciiExportOptionsJSON.data.getAsciiExport)
            if (getAsciiExportOptionsResponse.length > 0) {
                const asciiList = [];
                getAsciiExportOptionsResponse.forEach((x) => {
                    asciiList.push({ "label": x.AsciiExport_VC, "id": x.AsciiExport_ID })
                })
                setCustomerData(prevState => {
                    return { ...prevState, asciiExportOptions: asciiList }
                })
            }

            const beginDate = getDateFromEpoch(reportsQueryRequests.startDate);
            const endDateForQuery = getDateFromEpoch(reportsQueryRequests.endDate);

            const getExtraFieldsQuery = `{getExtraFieldsForSampleByAccount(accountID:${JSON.stringify(reportsQueryRequests.account)},startDate:${JSON.stringify(beginDate)},endDate:${JSON.stringify(endDateForQuery)},sampleType:${JSON.stringify(reportsQueryRequests.sampleType)},userID:${JSON.stringify(JSON.stringify(getUserIdResponse[0].UserID))})}`;
            const getExtraFieldsFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getExtraFieldsQuery }), headers: { 'Content-Type': 'application/json' } });
            const getExtraFieldsJSON = await getExtraFieldsFetch.json();
            const getExtraFieldsResponse = JSON.parse(getExtraFieldsJSON.data.getExtraFieldsForSampleByAccount);

            const getReportDataQuery = `{reportsInformation(account:${JSON.stringify(reportsQueryRequests.account)}, beginDate:${JSON.stringify(beginDate)}, endDate:${JSON.stringify(endDateForQuery)}, sampleTypeID:${JSON.stringify(reportsQueryRequests.sampleType)}, userID:${JSON.stringify(JSON.stringify(getUserIdResponse[0].UserID))})}`;
            const getReportDataFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getReportDataQuery }), headers: { 'Content-Type': 'application/json' } });
            const getReportDataJSON = await getReportDataFetch.json();
            const getReportDataResponse = JSON.parse(getReportDataJSON.data.reportsInformation)

            const reportData = [];
            getReportDataResponse.forEach((y) => {
                let extraFiled = [];
                getExtraFieldsResponse.forEach((x) => {
                    if (x.uniqueLabNumber === y.uniqueLabNumber) {
                        extraFiled.push({ "FieldName": x.extraField, "FieldValue": x.extraFieldValue })
                    }
                })
                let accountNum;
                if (y.AccountNumber) {
                    accountNum = y.AccountNumber
                } else {
                    accountNum = "";
                }
                let reportsInfo = [];
                const reportDataFromResponse = y.reports;
                reportDataFromResponse.forEach((r) => {
                    reportsInfo.push({ "ReportType": r.reportType, "ReportId": r.reportID })
                })
                if (extraFiled === []) {
                    reportData.push({
                        "LabNumber": y.labNumber,
                        "SampleID": y.sampleID,
                        "uniqueSampleIDs": y.uniqueSampleIDs,
                        "Producer": y.client,
                        "MaterialType": y.materialType,
                        "Received": Date.parse(y.dateReceived),
                        "Reported": Date.parse(y.dateReported),
                        "ReportType": y.reports[0].reportType,
                        "Location": y.location,
                        "ReportId": y.reports[0].reportID,
                        "AccountNumber": accountNum,
                        "ExtraFields": [],
                        "AccountName": y.accountNameCity,
                        "Reports": reportsInfo,
                        "UniqueLabNumber": y.uniqueLabNumber,
                        "HeaderInfo": [{
                            "LabLetter": "",
                            "ReportTypeID": "",
                            "PaperType": ""
                        }]
                    })
                } else {
                    reportData.push({
                        "LabNumber": y.labNumber,
                        "SampleID": y.sampleID,
                        "uniqueSampleIDs": y.uniqueSampleIDs,
                        "Producer": y.client,
                        "MaterialType": y.materialType,
                        "Received": Date.parse(y.dateReceived),
                        "Reported": Date.parse(y.dateReported),
                        "ReportType": y.reports[0].reportType,
                        "Location": y.location,
                        "ReportId": y.reports[0].reportID,
                        "AccountNumber": accountNum,
                        "ExtraFields": extraFiled,
                        "AccountName": y.accountNameCity,
                        "Reports": reportsInfo,
                        "UniqueLabNumber": y.uniqueLabNumber,
                        "HeaderInfo": [{
                            "LabLetter": "",
                            "ReportTypeID": "",
                            "PaperType": ""
                        }]
                    })
                }
            })
            setCardData(reportData)
            const materialTypesList = [...new Set(reportData.map(item => item.MaterialType))]
            materialTypesList.sort();
            setCustomerData(prevState => {
                return { ...prevState, materialTypes: materialTypesList }
            })
            const producerList = [...new Set(reportData.map(item => item.Producer))]
            producerList.sort();
            setCustomerData(prevState => {
                return { ...prevState, producers: producerList }
            })
        }
        setGettingCardData(false)

    }

    const handleAccount = async (e) => {
        setGettingListData(true)
        e.preventDefault();
        const value = e.target.innerText;
        let accNameForQuery;
        if (value !== 'All Accounts') {
            const string1 = value.split("(")
            const string2 = string1[1].split(")")
            accNameForQuery = string2[0]
        } else {
            accNameForQuery = 'All Accounts';
        }
        setReportsQueryRequests(prevState => {
            return { ...prevState, account: value }
        })
        const tsMonths = new Date().setMonth(new Date().getMonth() - 36);
        const beginDate = getDateFromEpoch(tsMonths);
        const endDate = getDateFromEpoch(today)
        const getReportDataQuery = `{reportsInformation(account:${JSON.stringify(accNameForQuery)}, beginDate:${JSON.stringify(beginDate)}, endDate:${JSON.stringify(endDate)}, sampleTypeID:${JSON.stringify(reportsQueryRequests.sampleType)}, userID:${JSON.stringify(reportsQueryRequests.userID)})}`;
        const getReportDataFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getReportDataQuery }), headers: { 'Content-Type': 'application/json' } });
        const getReportDataJSON = await getReportDataFetch.json();
        const getReportDataResponse = JSON.parse(getReportDataJSON.data.reportsInformation);
        const materialTypesList = [...new Set(getReportDataResponse.map(item => item.materialType))]
        materialTypesList.sort();
        const producerList = [...new Set(getReportDataResponse.map(item => item.client))]
        producerList.sort();
        setCustomerData(prevState => {
            return { ...prevState, producers: producerList, materialTypes: materialTypesList }
        })
        setGettingListData(false)
    }
    const handleSampleType = async (e) => {
        setGettingListData(true)
        e.preventDefault();
        const value = e.target.innerText;
        setReportsQueryRequests(prevState => {
            return { ...prevState, sampleType: value }
        })
        let accNameForQuery;
        if (reportsQueryRequests.account !== 'All Accounts') {
            const accNameString = reportsQueryRequests.account.split("(")
            const accName = accNameString[1].split(")");
            accNameForQuery = accName[0]
        } else {
            accNameForQuery = 'All Accounts'
        }
        const tsMonths = new Date().setMonth(new Date().getMonth() - 36);
        const beginDate = getDateFromEpoch(tsMonths);
        const endDate = getDateFromEpoch(today)
        const getReportDataQuery = `{reportsInformation(account:${JSON.stringify(accNameForQuery)}, beginDate:${JSON.stringify(beginDate)}, endDate:${JSON.stringify(endDate)}, sampleTypeID:${JSON.stringify(value)}, userID:${JSON.stringify(reportsQueryRequests.userID)})}`;
        const getReportDataFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getReportDataQuery }), headers: { 'Content-Type': 'application/json' } });
        const getReportDataJSON = await getReportDataFetch.json();
        const getReportDataResponse = JSON.parse(getReportDataJSON.data.reportsInformation);
        const materialTypesList = [...new Set(getReportDataResponse.map(item => item.materialType))]
        materialTypesList.sort();
        const producerList = [...new Set(getReportDataResponse.map(item => item.client))]
        producerList.sort();
        setCustomerData(prevState => {
            return { ...prevState, producers: producerList, materialTypes: materialTypesList }
        })
        setGettingListData(false)
    }

    const handleTimeSelect = (e) => {
        e.preventDefault();
        const time = e.target.innerText;
        setReportsQueryRequests(prevState => {
            return { ...prevState, presetTimeOption: time }
        })
        switch (time) {
            case 'Current Month':
                const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: firstDay.getTime() }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: today }
                })
                break;
            case 'Previous Month':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: prevMonth }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: today }
                })
                break;
            case '3 Months':
                const threeMonths = new Date().setMonth(new Date().getMonth() - 3);
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: threeMonths }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: today }
                })
                break;
            case '6 Months':
                const sixMonths = new Date().setMonth(new Date().getMonth() - 6);
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: sixMonths }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: today }
                })
                break;
            case 'Current Year':
                const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: firstDayYear.getTime() }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: today }
                })
                break;
            case '12 Months':
                const twelveMonths = new Date().setMonth(new Date().getMonth() - 12);
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: twelveMonths }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: today }
                })
                break;
            case '2 Years':
                const tfMonths = new Date().setMonth(new Date().getMonth() - 24);
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: tfMonths }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: today }
                })
                break;
            case '3 Years':
                const tsMonths = new Date().setMonth(new Date().getMonth() - 36);
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: tsMonths }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: today }
                })
                break;
            case '2022':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1640995200000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1672444800000 }
                })
                break;
            case '2021':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1609459200000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1640908800000 }
                })
                break;
            case '2020':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1577836800000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1609372800000 }
                })
                break;
            case '2019':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1546300800000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1577750400000 }
                })
                break;
            case '2018':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1514764800000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1546214400000 }
                })
                break;
            case '2017':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1483228800000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1514678400000 }
                })
                break;
            case '2016':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1451606400000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1483142400000 }
                })
                break;
            case '2015':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1420070400000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1451520000000 }
                })
                break;
            case '2014':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1388534400000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1419984000000 }
                })
                break;
            case '2013':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1356998400000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1388448000000 }
                })
                break;
            case '2012':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1325376000000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1356912000000 }
                })
                break;
            case '2011':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1293840000000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1325289600000 }
                })
                break;
            case '2010':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1262304000000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1293753600000 }
                })
                break;
            case '2009':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1230768000000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1262217600000 }
                })
                break;
            case '2008':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1199145600000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1230681600000 }
                })
                break;
            case '2007':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1167609600000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1199059200000 }
                })
                break;
            case '2006':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1136073600000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1167523200000 }
                })
                break;
            case '2005':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1104537600000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1135987200000 }
                })
                break;
            case '2004':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1072915200000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1104451200000 }
                })
                break;
            case '2003':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1041379200000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1072828800000 }
                })
                break;
            case '2002':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 1009843200000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1041292800000 }
                })
                break;
            case '2001':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 978307200000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 1009756800000 }
                })
                break;
            case '2000':
                setReportsQueryRequests(prevState => {
                    return { ...prevState, startDate: 946684800000 }
                })
                setReportsQueryRequests(prevState => {
                    return { ...prevState, endDate: 978220800000 }
                })
                break;
            default:
                break;
        }
    }

    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setShowAdvFilters(false) };
    const handleShow = () => setShow(true);
    const handleShowAdvancedFilters = () => {
        setShowAdvFilters(value => !value)
    }
    const handleSetNewStartDate = (date) => {
        setAdvFiltersData(prevState => {
            return { ...prevState, startDateForAdvFilters: date.getTime() }
        })
        setReportsQueryRequests(prevState => {
            return { ...prevState, presetTimeOption: 'Custom Time Selection', startDate: date.getTime() }
        })
    }
    const handleSetNewEndtDate = (date) => {
        setAdvFiltersData(prevState => {
            return { ...prevState, endDateForAdvFilters: date.getTime() }
        })
        setReportsQueryRequests(prevState => {
            return { ...prevState, presetTimeOption: 'Custom Time Selection', endDate: date.getTime() }
        })
    }
    const handleFilterForProducer = (e) => {
        e.preventDefault();
        let producer = e.target.innerText;
        if (producer === 'All Producers') {
            producer = "";
        }
        setAdvFiltersData(prevState => {
            return { ...prevState, selectedProducer: producer }
        })
    }
    const handleFilterForMaterialType = (e) => {
        e.preventDefault();
        let materialType = e.target.innerText;
        if (materialType === "All Sample Material Types") {
            materialType = "";
        }
        setAdvFiltersData(prevState => {
            return { ...prevState, selectedMaterialType: materialType }
        })
    }
    const handleApplyFilters = async () => {
        setSampleTypeForPlaceholder(reportsQueryRequests.sampleType)
        setGettingCardData(true)
        setRemovePdfs(true)
        setShowAdvFilters(false)
        setShow(false)
        setSelectedCard("")
        setPdfsToEmail([{
            ReportId: ""
        }])
        setSamplesToExport([{
            uniqueSampleIDs: "",
            LabNumber:""
        }])
        setcheckedCardsIDss("")
        setReportsQueryRequests(prevState  => {
           return {...prevState, selectedExportType: "", selectedExportTypeId: ""}
        })
        const beginDate = getDateFromEpoch(advFiltersData.startDateForAdvFilters);
        const endDateForQuery = getDateFromEpoch(advFiltersData.endDateForAdvFilters);
        let accNameForQuery;
        if (reportsQueryRequests.account !== 'All Accounts') {
            const accNameString = reportsQueryRequests.account.split("(")
            const accName = accNameString[1].split(")");
            accNameForQuery = accName[0]
        } else {
            accNameForQuery = 'All Accounts'
        }

        const getAsciiExportOptionsQuery = `{getAsciiExport(sampleTypeID:${JSON.stringify(reportsQueryRequests.sampleType)}, userID:${JSON.stringify(reportsQueryRequests.userID)})}`;
        const getAsciiExportOptionsFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getAsciiExportOptionsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getAsciiExportOptionsJSON = await getAsciiExportOptionsFetch.json();
        const getAsciiExportOptionsResponse = JSON.parse(getAsciiExportOptionsJSON.data.getAsciiExport)
        if (getAsciiExportOptionsResponse.length > 0) {
            const asciiList = [];
            getAsciiExportOptionsResponse.forEach((x) => {
                asciiList.push({ "label": x.AsciiExport_VC, "id": x.AsciiExport_ID })
            })
            setCustomerData(prevState => {
                return { ...prevState, asciiExportOptions: asciiList }
            })
        }

        const getExtraFieldsQuery = `{getExtraFieldsForSampleByAccount(accountID:${JSON.stringify(accNameForQuery)}, startDate:${JSON.stringify(beginDate)}, endDate:${JSON.stringify(endDateForQuery)}, sampleType:${JSON.stringify(reportsQueryRequests.sampleType)}, userID:${JSON.stringify(reportsQueryRequests.userID)})}`;
        const getExtraFieldsFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getExtraFieldsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getExtraFieldsJSON = await getExtraFieldsFetch.json();
        const getExtraFieldsResponse = JSON.parse(getExtraFieldsJSON.data.getExtraFieldsForSampleByAccount);

        const getReportDataQuery = `{reportsInformation(account:${JSON.stringify(accNameForQuery)}, beginDate:${JSON.stringify(beginDate)}, endDate:${JSON.stringify(endDateForQuery)}, sampleTypeID:${JSON.stringify(reportsQueryRequests.sampleType)}, userID:${JSON.stringify(reportsQueryRequests.userID)}, producerChose:${JSON.stringify(advFiltersData.selectedProducer)}, materialTypeChose:${JSON.stringify(advFiltersData.selectedMaterialType)})}`;
        const getReportDataFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getReportDataQuery }), headers: { 'Content-Type': 'application/json' } });
        const getReportDataJSON = await getReportDataFetch.json();
        const getReportDataResponse = JSON.parse(getReportDataJSON.data.reportsInformation);

        const reportData = [];
        getReportDataResponse.forEach((y) => {
            let extraFiled = [];
            getExtraFieldsResponse.forEach((x) => {
                if (x.uniqueLabNumber === y.uniqueLabNumber) {
                    extraFiled.push({ "FieldName": x.extraField, "FieldValue": x.extraFieldValue })
                }
            })
            let accountNum;
            if (y.AccountNumber) {
                accountNum = y.AccountNumber
            } else {
                accountNum = "";
            }
            let reportsInfo = [];
            const reportDataFromResponse = y.reports;
            reportDataFromResponse.forEach((r) => {
                reportsInfo.push({ "ReportType": r.reportType, "ReportId": r.reportID })
            })
            if (extraFiled === []) {
                reportData.push({
                    "LabNumber": y.labNumber,
                    "SampleID": y.sampleID,
                    "uniqueSampleIDs": y.uniqueSampleIDs,
                    "Producer": y.client,
                    "MaterialType": y.materialType,
                    "Received": Date.parse(y.dateReceived),
                    "Reported": Date.parse(y.dateReported),
                    "ReportType": y.reports[0].reportType,
                    "Location": y.location,
                    "ReportId": y.reports[0].reportID,
                    "AccountNumber": accountNum,
                    "ExtraFields": [],
                    "AccountName": y.accountNameCity,
                    "Reports": reportsInfo,
                    "UniqueLabNumber": y.uniqueLabNumber,
                    "HeaderInfo": [{
                        "LabLetter": "",
                        "ReportTypeID": "",
                        "PaperType": ""
                    }]
                })
            } else {
                reportData.push({
                    "LabNumber": y.labNumber,
                    "SampleID": y.sampleID,
                    "uniqueSampleIDs": y.uniqueSampleIDs,
                    "Producer": y.client,
                    "MaterialType": y.materialType,
                    "Received": Date.parse(y.dateReceived),
                    "Reported": Date.parse(y.dateReported),
                    "ReportType": y.reports[0].reportType,
                    "Location": y.location,
                    "ReportId": y.reports[0].reportID,
                    "AccountNumber": accountNum,
                    "ExtraFields": extraFiled,
                    "AccountName": y.accountNameCity,
                    "Reports": reportsInfo,
                    "UniqueLabNumber": y.uniqueLabNumber,
                    "HeaderInfo": [{
                        "LabLetter": "",
                        "ReportTypeID": "",
                        "PaperType": ""
                    }]
                })
            }
        })
        setCardData(reportData)
        setRemovePdfs(false)
        setGettingCardData(false)

    }


    const handleUpdatReportDataForCard = (uniqueLabNumber, reportType, reportId, e) => {
        e.preventDefault();
        let updatesCard = [];
        cardData.forEach(async (x) => {
            if (x.UniqueLabNumber === uniqueLabNumber) {

                updatesCard.push({
                    "LabNumber": x.LabNumber,
                    "SampleID": x.SampleID,
                    "uniqueSampleIDs": x.uniqueSampleIDs,
                    "Producer": x.Producer,
                    "MaterialType": x.MaterialType,
                    "Received": x.Received,
                    "Reported": x.Reported,
                    "ReportType": reportType,
                    "Location": x.Location,
                    "ReportId": reportId,
                    "AccountNumber": x.AccountNumber,
                    "ExtraFields": x.ExtraFields,
                    "AccountName": x.AccountName,
                    "Reports": x.Reports,
                    "UniqueLabNumber": x.UniqueLabNumber,
                    "HeaderInfo": x.HeaderInfo
                })
            } else {
                updatesCard.push({
                    "LabNumber": x.LabNumber,
                    "SampleID": x.SampleID,
                    "uniqueSampleIDs": x.uniqueSampleIDs,
                    "Producer": x.Producer,
                    "MaterialType": x.MaterialType,
                    "Received": x.Received,
                    "Reported": x.Reported,
                    "ReportType": x.ReportType,
                    "Location": x.Location,
                    "ReportId": x.ReportId,
                    "AccountNumber": x.AccountNumber,
                    "ExtraFields": x.ExtraFields,
                    "AccountName": x.AccountName,
                    "Reports": x.Reports,
                    "UniqueLabNumber": x.UniqueLabNumber,
                    "HeaderInfo": x.HeaderInfo
                })
            }
        })
        setCardData(updatesCard)
    }
    const handleCardsChecked = async (card) => {
        const ReportId = card.ReportId;
        const uniqueSampleIDs = card.uniqueSampleIDs;
        console.log(card);
        if (checkedCardsIDs === "" || checkedCardsIDs === []) {
            setcheckedCardsIDss([ReportId]);
            setPdfsToEmail([{
                ReportId: card.ReportId
            }])
            setSamplesToExport([{
                uniqueSampleIDs: card.uniqueSampleIDs,
                LabNumber:card.LabNumber
            }])
        } else {
            const anotherSelected = [];
            const anotherPdf = [];
            const moreSampleIds = [];
            pdfsToEmail.forEach((x, index) => {
                // if (x.ReportId !== ReportId && checkedCardsIDs.length <= 6) {
                if (x.ReportId !== ReportId) {
                    anotherSelected.push(x.ReportId);
                    anotherPdf.push({
                        ReportId: x.ReportId
                    })
                }
            })
            samplesToExport.forEach((x) => {
                if (x.uniqueSampleIDs !== uniqueSampleIDs) {
                    moreSampleIds.push({
                        uniqueSampleIDs: x.uniqueSampleIDs,
                        LabNumber:x.LabNumber
                    })
                }
            })
            if (checkedCardsIDs.includes(ReportId)) {
                setcheckedCardsIDss(anotherSelected)
                setPdfsToEmail(anotherPdf)
                setSamplesToExport(moreSampleIds)
            } else {
                setcheckedCardsIDss(prevIDs => {
                    return ([...prevIDs, ReportId])
                })
                setPdfsToEmail(prevPdfs => {
                    return ([...prevPdfs, {
                        ReportId: card.ReportId
                    }])
                })
                setSamplesToExport(prevExports => {
                    return ([...prevExports, {
                        uniqueSampleIDs: card.uniqueSampleIDs, LabNumber:card.LabNumber
                    }])
                })
            }

        }
    }
    const hanldeSelectAllCards = () => {
        const allCards = cardData;
        let allReportIDs = [];
        let forPdfs = [];
        let idsToAdd = [];
        let forExports = [];
        allCards.forEach((x) => {
            idsToAdd.push(x.ReportId)
            forExports.push({ uniqueSampleIDs: x.uniqueSampleIDs, LabNumber:x.LabNumber })
        })
        const uniqueReportId = [...new Set(idsToAdd)];
        uniqueReportId.forEach((x) => {
            allReportIDs.push(x)
            forPdfs.push({
                ReportId: x
            })
        })
        setcheckedCardsIDss(allReportIDs)
        setPdfsToEmail(forPdfs)
        setSamplesToExport(forExports)
    }

    const handleDeselectAllCards = () => {
        setcheckedCardsIDss("")
        setPdfsToEmail([{ ReportId: "" }])
        setSamplesToExport([{ uniqueSampleIDs: "", LabNumber:"" }])
    }

    const handleSetSelectedCard = (card, e) => {
        setSelectedCard(card)
    }

    const handleSelectExportType = (option) => {
        let exportTypeLabel = option.label;
        let exportTypeId = option.id;
        setReportsQueryRequests(prevState => {
            return { ...prevState, selectedExportType: exportTypeLabel, selectedExportTypeId: exportTypeId }
        })
    }

    const fetchEmails = async () => {
        const getEmailsQuery = `{getEmailList(userID:${JSON.stringify(reportsQueryRequests.userID)})}`;
        const getEmailsFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: getEmailsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getEmailsJSON = await getEmailsFetch.json();
        const emailsForList = JSON.parse(getEmailsJSON.data.getEmailList)
        let newElist = [];
        emailsForList.forEach((x) => {
            newElist.push({
                email: x.EmailAddress,
                emailId: x.EmailAddressID
            })
        })
        setEmailList(newElist)
    }

    const handleShowEmailModal = async () => {
        let linkReports = 'https://report-pdf-node-bjudfyf5za-uc.a.run.app/getReportsForEmail/';
        pdfsToEmail.forEach((x, index) => {
            if ((index === (pdfs.length - 1) || index === 0)) {
                linkReports = linkReports + x.ReportId
            } else {
                linkReports = linkReports + ',' + x.ReportId
            }
        })
        fetch(linkReports).then((response) => response.json()).then((data) => setPDFA(data))
        await fetchEmails();
        setShowEmailModal(true)
    }

    const handleAddEmailToList = async (emailAddress) => {
        if (emailAddress === "") {
            setDisplayMessage("Please enter a valid email address")
            setShowEmailModal(false)
            setShowMessageModal(true)
            setTimeout(() => {
                setShowEmailModal(true)
                setShowMessageModal(false)
                setDisplayMessage("")
            }, 3500)
        } else {
            const addNewEmailQuery = `{insertNewEmail(userID:${JSON.stringify(reportsQueryRequests.userID)}, emailAddress:${JSON.stringify(emailAddress)})}`;
            const addNewEmailFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: addNewEmailQuery }), headers: { 'Content-Type': 'application/json' } });
            const addNewEmailJSON = await addNewEmailFetch.json();
            await fetchEmails();
        }
    }
    const handleDeleteEmailFromList = async (emailToDelete) => {
        const deleteEmailQuery = `{deleteEmail(emailAddressID:${JSON.stringify(JSON.stringify(emailToDelete.emailId))})}`;
        const deleteEmailFetch = await fetch(reportsGraphql, { method: 'POST', body: JSON.stringify({ query: deleteEmailQuery }), headers: { 'Content-Type': 'application/json' } });
        const deleteEmailJSON = await deleteEmailFetch.json();
        await fetchEmails();
    }

    const handleEmailReports = async (emailMessage) => {
        if (checkedEmailList === "" || checkedEmailList.length < 1) {
            setBackupEmailMessage(emailMessage)
            setDisplayMessage("Please select an email address")
            setShowEmailModal(false)
            setShowMessageModal(true)
            setTimeout(() => {
                setShowMessageModal(false)
                setDisplayMessage("")
                setShowEmailModal(true)
            }, 3500)
        }
        else {
            setSendingEmail(true)
            const emailToList = JSON.stringify(checkedEmailList)
            const reports = JSON.stringify(pdfAttachment)
            const message = JSON.stringify(emailMessage);
            const emailQuery = `{sendEmailReportsFromWebsite(message:${message}, emailTo:${JSON.stringify(emailToList)}, reports:${JSON.stringify(reports)})}`
            const emailFetch = await fetch(emailGraphql, { method: 'POST', body: JSON.stringify({ query: emailQuery }), headers: { 'Content-Type': 'application/json' } });
            const emailJSON = await emailFetch.json();
            const emailResponse = emailJSON.data.sendEmailReportsFromWebsite
            if (emailResponse === 'email sent') {
                setShowEmailModal(false)
                if (emailMessage === "") {
                    setDisplayMessage("The selected reports have been emailed!")
                } else {
                    setDisplayMessage("The selected reports and message have been emailed!")
                }
                setShowMessageModal(true)
                setSendingEmail(false)

                setBackupEmailMessage("")
                setTimeout(() => {
                    setShowMessageModal(false)
                    setDisplayMessage("")
                    // setcheckedCardsIDss("")
                    setCheckedEmailList([])

                    // setPdfsToEmail([{ ReportId: "" }])
                }, 4000)

            }
        }
    }

    //temp print?
    // const handlePrint = useReactToPrint({
    //     content: () => printRef.current,
    // });

    const handlePrint = () => {
        let linkReports = 'https://report-pdf-node-bjudfyf5za-uc.a.run.app/getMultipleReports/';
        pdfsToEmail.forEach((x, index) => {
            if ((index === (pdfs.length - 1) || index === 0)) {
                linkReports = linkReports + x.ReportId
            } else {
                linkReports = linkReports + ',' + x.ReportId
            }
        })
        window.open(linkReports)
    }

    const handleNoReportsSelected = () => {
        setDisplayMessage('No reports checked!')
        setShowMessageModal(true);
        setTimeout(() => {
            setShowMessageModal(false);
            setDisplayMessage("");
        }, 3500)
    }

    const handleTooManyReportsSelected = (e, numReports, action) => {
        e.preventDefault()
        if (action === 'export') {
            setDisplayMessage('Cannot export until at least one sample is selected and an export type is chosen!')
        } else if (action === 'export error') {
            setDisplayMessage('Cannot export file at the moment')
        } else {
            setDisplayMessage(`Too many reports selected! You can only ${action} ${numReports} reports at a time.`)
        }
        setShowMessageModal(true);
        setTimeout(() => {
            setShowMessageModal(false);
            setDisplayMessage("");
        }, 3500)
    }

    const handleEmailChecked = (address) => {
        const emailToUse = address
        if (checkedEmailList === "" || checkedEmailList === []) {
            setCheckedEmailList([emailToUse]);
        } else {
            const anotherSelected = [];
            checkedEmailList.forEach((x, index) => {
                // if (x.ReportId !== ReportId && checkedCardsIDs.length <= 6) {
                if (x !== emailToUse) {
                    anotherSelected.push(x);
                }
            })
            if (checkedEmailList.includes(emailToUse)) {
                setCheckedEmailList(anotherSelected)
            } else {
                setCheckedEmailList(prevAddresses => {
                    return ([...prevAddresses, emailToUse])
                })
            }

        }
    }

    useEffect(() => {
        window.addEventListener("DOMContentLoaded", fetchData())
    }, [])

    useEffect(() => {
        setAdvFiltersData({
            startDateForAdvFilters: reportsQueryRequests.startDate,
            endDateForAdvFilters: reportsQueryRequests.endDate,
            selectedProducer: "",
            selectedMaterialType: ""
        })
    }, [reportsQueryRequests])
    return (
        <>
            <EmailModal
                isOpen={showEmailModal}
                setShowEmailModal={setShowEmailModal}
                handleEmailReports={handleEmailReports}
                sendingEmail={sendingEmail}
                emailList={emailList}
                checkedEmailList={checkedEmailList}
                handleEmailChecked={handleEmailChecked}
                handleAddEmailToList={handleAddEmailToList}
                handleDeleteEmailFromList={handleDeleteEmailFromList}
                backupEmailMessage={backupEmailMessage}
                setBackupEmailMessage={setBackupEmailMessage}
            />
            <MessageModal
                isOpen={showMessageModal}
                setShowEmailModal={setShowMessageModal}
                displayMessage={displayMessage}
            />
            <Routes>
                <Route path="/" element={
                    <Reports
                        customerData={customerData}
                        cardData={cardData}
                        handleCardsChecked={handleCardsChecked}
                        checkedCardsIDs={checkedCardsIDs}
                        handleSetSelectedCard={handleSetSelectedCard}
                        selectedCard={selectedCard}
                        reportsQueryRequests={reportsQueryRequests}
                        handleAccount={handleAccount}
                        handleSampleType={handleSampleType}
                        handleTimeSelect={handleTimeSelect}
                        handleSetNewStartDate={handleSetNewStartDate}
                        handleSetNewEndtDate={handleSetNewEndtDate}
                        getDateFromEpoch={getDateFromEpoch}
                        // printRef={printRef}
                        pdfsToEmail={pdfsToEmail}
                        handleShowEmailModal={handleShowEmailModal}
                        handlePrint={handlePrint}
                        handleNoReportsSelected={handleNoReportsSelected}
                        handleTooManyReportsSelected={handleTooManyReportsSelected}
                        handleFilterForProducer={handleFilterForProducer}
                        handleFilterForMaterialType={handleFilterForMaterialType}
                        advFiltersData={advFiltersData}
                        showAdvFilters={showAdvFilters}
                        handleShowAdvancedFilters={handleShowAdvancedFilters}
                        handleApplyFilters={handleApplyFilters}
                        removePdfs={removePdfs}
                        gettingCardData={gettingCardData}
                        setShowAdvFilters={setShowAdvFilters}
                        show={show}
                        handleClose={handleClose}
                        handleShow={handleShow}
                        sampleTypeForPlaceholder={sampleTypeForPlaceholder}
                        gettingListData={gettingListData}
                        handleUpdatReportDataForCard={handleUpdatReportDataForCard}
                        handleSelectExportType={handleSelectExportType}
                        hanldeSelectAllCards={hanldeSelectAllCards}
                        handleDeselectAllCards={handleDeselectAllCards}
                        samplesToExport={samplesToExport}
                    />
                }
                />
                <Route path="/lab-report" element={<MobileReportsView pdfsToEmail={pdfsToEmail} removePdfs={removePdfs} selectedCard={selectedCard} />} />
            </Routes>
        </>
    )
}
export default App;