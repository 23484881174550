
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './styles.css';
import { Row, Col } from 'react-bootstrap';

const PdfViewer = (props) => {
    // Needed to get base64String PDF to load
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const {
        selectedCard,
        pdfScale,
        removePdfs
    } = props;
    const [numPages, setNumPages] = useState(0);
    const [pdf, setPdf] = useState("");
    const [currentReport, setCurrentReport] = useState("");

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(Array.from(Array(numPages).keys()));
    }
    function loadError() {
        console.log('error loading file');
    }


    useEffect(() => {
        if ((selectedCard !== "" ) && (selectedCard.ReportId !== currentReport) ) {
            setCurrentReport(selectedCard.ReportId)
            setNumPages(0)
            setPdf(`https://report-pdf-node-bjudfyf5za-uc.a.run.app/${selectedCard.ReportId}`)
            
        } else if (selectedCard.ReportId === currentReport) {
            //Do Nothing
        } else {
            setPdf("")
            setNumPages(0)
        }
        if(removePdfs === true){
            setPdf("")
            setNumPages(0)
        }
    }, [selectedCard, removePdfs])

    return (
        <>
            {pdf === "" &&
                <div className='no-pdf'>Quick Report View (Shows PDF)</div>
            }
            {pdf !== "" &&
                <Col className='report-preview-div' style={{textAlign: "center"}}>
                    <Row>
                        <Document
                            className='report-document'
                            file={pdf}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={loadError}
                        >

                            {numPages !== 0 && numPages.map((x, index) =>
                                <div key={index}>
                                    <Page scale={pdfScale} key={`page_${index + 1}`} pageNumber={index + 1} className='page'/>
                                </div>
                            )}
                        </Document>
                    </Row>

                </Col>
            }
        </>
    )
}
export default PdfViewer;