import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.css'

import PdfViewer from './PdfViewer'
import Header from './Header'

const MobileReportsView = (props) => {
    const {
        pdfsToEmail,
        selectedCard,
        removePdfs
    } = props;


    return (
        <>
            <Header />
            <div style={{overflowX:"hidden", paddingLeft:"10px", paddingRight:"10px"}}>
            <PdfViewer pdfsToEmail={pdfsToEmail} removePdfs={removePdfs} selectedCard={selectedCard} pdfScale={1}/>
            </div>
        </>
    )
}
export default MobileReportsView;