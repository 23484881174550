import { Modal } from 'react-bootstrap';
import '../styles.css'

import Filters from '../Filters';

const FiltersModal = (props) => {
    const {
        isOpen,
        handleClose,
        customerData,
        reportsQueryRequests,
        handleAccount,
        handleSampleType,
        handleTimeSelect,
        handleSetNewStartDate,
        handleSetNewEndtDate,
        handleFilterForProducer,
        handleFilterForMaterialType,
        selectedMaterialType,
        selectedProducer,
        advFiltersData,
        showAdvFilters,
        handleApplyFilters,
        gettingListData,
    } = props;

    const handleOnHide = () => {
        handleClose();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen}
            onHide={handleOnHide}
        >
            <Modal.Body>
                <Filters
                    customerData={customerData}
                    reportsQueryRequests={reportsQueryRequests}
                    handleAccount={handleAccount}
                    handleSampleType={handleSampleType}
                    handleTimeSelect={handleTimeSelect}
                    handleSetNewStartDate={handleSetNewStartDate}
                    handleSetNewEndtDate={handleSetNewEndtDate}
                    handleFilterForProducer={handleFilterForProducer}
                    handleFilterForMaterialType={handleFilterForMaterialType}
                    selectedMaterialType={selectedMaterialType}
                    selectedProducer={selectedProducer}
                    advFiltersData={advFiltersData}
                    showAdvFilters={showAdvFilters}
                    handleApplyFilters={handleApplyFilters}
                    gettingListData={gettingListData}
                />
            </Modal.Body>
        </Modal>
    )
}

export default FiltersModal;