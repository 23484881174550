import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Card, Button, Spinner, Dropdown } from 'react-bootstrap';
import PdfViewer from './PdfViewer';
import { Code } from 'react-feather';
import { FiltersModal } from './Modals';

import { Link } from "react-router-dom"

import './styles.css'
<link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@48,400,0,0" />

const SelectForReportType = (props) => {
    const { card, handleUpdatReportDataForCard } = props;

    const reportCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="reports-custom-toggle-button"
        >
            <span className='toggle-label'>{card.ReportType}</span>
            <Code className="toggle-icon" size={15} />
            {children}
        </Button>
    ));

    return (
        <>
            <Dropdown style={{ marginTop: "-4.8px" }}>
                <Dropdown.Toggle as={reportCustomToggle}>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: "100%" }}>
                    {card.Reports.map((m, index) =>
                        <Dropdown.Item
                            value={m.ReportType}
                            key={index}
                            style={{ fontSize: "10pt" }}
                            onClick={(e) => { handleUpdatReportDataForCard(card.UniqueLabNumber, m.ReportType, m.ReportId, e) }}
                        >
                            {m.ReportType}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );

}

const ReportsArea = (props) => {
    const {
        customerData,
        cardData,
        handleCardsChecked,
        checkedCardsIDs,
        handleSetSelectedCard,
        selectedCard,
        reportsQueryRequests,
        handleAccount,
        handleSampleType,
        handleTimeSelect,
        handleSetNewStartDate,
        handleSetNewEndtDate,
        getDateFromEpoch,
        pdfsToEmail,
        handleFilterForProducer,
        handleFilterForMaterialType,
        advFiltersData,
        showAdvFilters,
        handleShowAdvancedFilters,
        handleApplyFilters,
        gettingCardData,
        show,
        handleClose,
        handleShow,
        gettingListData,
        handleUpdatReportDataForCard,
        sampleTypeForPlaceholder,
        hanldeSelectAllCards,
        handleDeselectAllCards,
        removePdfs
    } = props;
    let s = [];


    const [sampleCards, setSampleCards] = useState(cardData);
    const [filterLabel, setFilterLabel] = useState('');
    const [reportsCount, setReportsCount] = useState(0);


    function getNestedStrings(obj, level) {
        if (obj) {
            if (level === 1) { s = [] }
            const valArr = Object.values(obj);
            const strArr = valArr.filter((o) => { return (typeof o === 'string') });
            const objArr = valArr.filter((o) => { return (typeof o === 'object') });
            if (strArr.length > 0) { s = [...s, ...strArr]; };
            objArr.forEach((x) => { getNestedStrings(x, level + 1) });
            if (level === 1) { return s; };
        }
    };
    const handleSearch = (e) => {
        let searchString;
        if (e !== undefined) {
            e.preventDefault();
            searchString = e.target.value.toLowerCase();
        } else {
            searchString = "";
        }
        if (searchString.length) {
            const res = (cardData.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(searchString.toLowerCase()))
                }))
            }));

            setSampleCards(res)  // show result object(s)

        } else {
            setSampleCards(cardData)
        }
    }
    const handleSearchBug = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }

    const isChecked = (id) => {
        return (checkedCardsIDs.indexOf(id) > -1);
    }
    const handleSearchReset = () => {
        document.getElementById("search-form").reset();
        handleSearch();
    }

    useEffect(() => {
        setFilterLabel(`Start typing to filter ${sampleTypeForPlaceholder}s...`)
    }, [sampleTypeForPlaceholder])

    useEffect(() => {
        setSampleCards(cardData)
        let newArr = [];
        cardData.forEach((x) => {
            newArr.push(x.ReportId)
        });
        const reportCounter = [...new Set(newArr)];
        setReportsCount(reportCounter)
    }, [cardData])

    return (
        <>
            <link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

            <Row className='report-display' >
                <Col xs={12} s={12} md={12} lg={5} xl={3} style={{ paddingLeft: "0", paddingRight: "0" }} className='laptop-width'>
                    <Col xs={12} s={12} md={12} lg={12} xl={12} style={{ paddingLeft: "0", paddingTop: ".3vh" }}>
                        <Row style={{ width: "100%", paddingLeft: "0", marginLeft: "0" }}>
                            <Col xs={2} s={2} md={2} lg={2} xl={2} style={{ paddingLeft: "0", paddingRight: "0" }}>
                                {!gettingCardData &&
                                    <Button
                                        variant="primary"
                                        className="filters-button"
                                        style={{ margin: "0", height: "100%", display: "inline-flex", justifyContent: "center", alignItems: "center" }}
                                        onClick={() => { handleShowAdvancedFilters(); handleShow(); }}
                                    >
                                        Filters
                                    </Button>
                                }
                                {gettingCardData &&
                                    <Button
                                        variant="secondary"
                                        className="filters-button"
                                        style={{ margin: "0", height: "100%", display: "inline-flex", justifyContent: "center", alignItems: "center" }}
                                    // onClick={() => { handleShowAdvancedFilters(); handleShow(); }}
                                    >
                                        Filters
                                    </Button>
                                }
                            </Col>
                            <Col xs={8} s={8} md={8} lg={8} xl={8} style={{ paddingLeft: "0", paddingRight: "0" }}>
                                <span style={{ float: "right", marginRight: "30px" }}>
                                    <span className="material-symbols-outlined"
                                        style={{
                                            position: "absolute",
                                            marginTop: "7px",
                                            cursor: "pointer"
                                        }}
                                        onClick={handleSearchReset}
                                    >
                                        close
                                    </span></span>
                                <form id="search-form">
                                    <Form.Control
                                        as="input"
                                        type="input"
                                        size="md"
                                        placeholder={filterLabel}
                                        className="search-input-field"
                                        onChange={(e) => handleSearch(e)}
                                        onKeyDown={handleSearchBug}
                                    />
                                </form>
                            </Col>
                            {!gettingCardData &&
                                <Col xs={2} s={2} md={2} lg={2} xl={2} style={{ paddingLeft: "0", paddingRight: "0", maxHeight: "36px", textAlign: "center", display: "block" }}>
                                    <Row className='d-none d-lg-block'>
                                        {(checkedCardsIDs.length !== reportsCount.length) &&
                                            <span className='card-count'>
                                                Select All
                                            </span>
                                        }
                                        {(checkedCardsIDs.length === reportsCount.length) &&
                                            <span className='card-count'>
                                                Deselect All
                                            </span>
                                        }
                                    </Row>
                                    <Row className='d-lg-none' style={{ paddingLeft: "2px" }}>
                                        {(checkedCardsIDs.length !== reportsCount.length) &&
                                            <span className='card-count' style={{ fontSize: "11px" }}>
                                                Select All
                                            </span>
                                        }
                                        {(checkedCardsIDs.length === reportsCount.length) &&
                                            <span className='card-count' style={{ fontSize: "11px" }}>
                                                Deselect All
                                            </span>
                                        }
                                    </Row>
                                    <Row style={{ marginRight: "-6px", marginTop: "-4px" }}>
                                        {(checkedCardsIDs.length !== reportsCount.length) &&
                                            <span className="material-symbols-outlined"
                                                style={{
                                                    color: "black",
                                                    padding: "0",
                                                    margin: "0",
                                                    cursor:"pointer"
                                                }}
                                                onClick={hanldeSelectAllCards}
                                            >
                                                check_box_outline_blank
                                            </span>
                                        }
                                        {(checkedCardsIDs.length === reportsCount.length) &&
                                            <span className="material-symbols-outlined"
                                                style={{
                                                    color: "black",
                                                    padding: "0",
                                                    margin: "0",
                                                    cursor:"pointer"
                                                }}
                                                onClick={handleDeselectAllCards}
                                            >
                                                check_box
                                            </span>
                                        }
                                    </Row>
                                </Col>
                            }
                        </Row>
                    </Col>
                    {!gettingCardData &&
                        <Col className='card-list' xs={12} s={12} md={12} lg={12} xl={12} >
                            <Card className='totals-card'>
                                <Row style={{ marginRight: "0", paddingRight: "0", justifyContent: "center" }}>
                                    Selected Reports: {checkedCardsIDs.length} (Print Limit: 20, Email Limit: 6)
                                </Row>
                            </Card>
                            {
                                sampleCards.map((data, index) =>
                                    <Card
                                        className='report-card'
                                        key={index}
                                        style={{ background: selectedCard.ReportId === data.ReportId ? "#a1d6f7" : "#ffffff", }}
                                    >
                                        <Row
                                            style={{ marginRight: "0", paddingRight: "0", backgroundColor: "#4A525A", }}
                                        >
                                            <Col xs={11} s={11} md={11} lg={11} xl={11}
                                                style={{
                                                    textAlign: "left",
                                                    padding: "0",
                                                    margin: "0"
                                                }}
                                            >
                                                <span className='labNumberSpan' style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                }}>#{data.LabNumber}</span>
                                                <p style={{ color: "white", fontSize: "11.8" }} className='text-value'>{data.AccountName}</p>
                                            </Col>
                                            <Col xs={1} s={1} md={1} lg={1} xl={1} style={{ padding: "0", margin: "0" }}>
                                                {!isChecked(data.ReportId) &&
                                                    <span className="material-symbols-outlined"
                                                        style={{
                                                            color: "white",
                                                            padding: "0",
                                                            margin: "0",
                                                            cursor:"pointer"
                                                        }}
                                                        id={'checkBox' + index}
                                                        onClick={() => handleCardsChecked(data)}>
                                                        check_box_outline_blank
                                                    </span>
                                                }
                                                {isChecked(data.ReportId) &&
                                                    <span className="material-symbols-outlined"
                                                        style={{
                                                            color: "white",
                                                            padding: "0",
                                                            margin: "0",
                                                            cursor:"pointer"
                                                        }}
                                                        id={'checkBox' + index}
                                                        onClick={() => handleCardsChecked(data)}>
                                                        check_box
                                                    </span>
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={{ marginRight: "0", paddingRight: "0", lineHeight: "10px" }}>
                                            {(reportsQueryRequests.sampleType !== 'Soil' && data.SampleID !== '' && data.SampleID !== '-- Multiple --') &&
                                                <Col style={{ paddingLeft: "0" }}>

                                                    <span className="info-label">
                                                        Sample ID
                                                    </span>
                                                    <span className='text-value'>&nbsp;{data.SampleID}</span>

                                                </Col>
                                            }
                                            {(reportsQueryRequests.sampleType !== 'Soil' && data.MaterialType !== '') &&
                                                <Col style={{ paddingLeft: "0" }}>
                                                    <span className="info-label">
                                                        Material Type
                                                    </span>
                                                    &nbsp;
                                                    <span
                                                        className='text-value'
                                                    >
                                                        {data.MaterialType}
                                                    </span>
                                                </Col>
                                            }
                                            {data.Producer !== '' &&
                                                <Col style={{ paddingLeft: "0" }}>
                                                    <span className="info-label">
                                                        Producer
                                                    </span>
                                                    &nbsp;
                                                    <span className='text-value'
                                                    >
                                                        {data.Producer}
                                                    </span>
                                                </Col>
                                            }
                                            {data.Location !== '' &&
                                                <Col>

                                                    <span className="info-label">
                                                        Location
                                                    </span>
                                                    &nbsp;
                                                    <span
                                                        className='text-value'
                                                    >
                                                        {data.Location}
                                                    </span>

                                                </Col>
                                            }
                                        </Row>
                                        {
                                            data.ExtraFields !== [] &&
                                            <Row style={{ marginRight: "0", paddingRight: "0", lineHeight: "14px" }}>
                                                {data.ExtraFields.map((x, index) =>
                                                    <Col className="info-label-col" key={index}>
                                                        {x.FieldName !== '-- Multiple --' &&
                                                            <span>
                                                                <span className="info-label">
                                                                    {x.FieldName}
                                                                </span>
                                                                <p className='text-value' >{x.FieldValue}</p>
                                                            </span>
                                                        }
                                                    </Col>
                                                )}
                                            </Row>
                                        }
                                        <Row style={{ marginRight: "0", paddingRight: "0", marginTop: "-5px" }}>
                                            <Col className="info-label-col" >
                                                <span className="info-label">
                                                    Received
                                                </span>
                                                <p className='text-value' >{getDateFromEpoch(data.Received)}</p>
                                            </Col>
                                            <Col className="info-label-col" >
                                                <span className="info-label">
                                                    Reported
                                                </span>
                                                <p className='text-value' >{getDateFromEpoch(data.Reported)}</p>
                                            </Col>
                                            <Col className="info-label-col" >
                                                <span className="info-label">
                                                    Report Type
                                                </span>
                                                <SelectForReportType
                                                    card={data}
                                                    handleUpdatReportDataForCard={handleUpdatReportDataForCard}
                                                />
                                            </Col>
                                            <Col style={{ padding: "0", margin: "0" }}>
                                                {/* View button for mobile */}
                                                <div className='d-lg-none'>
                                                    <span className='mobile-view-link' onClick={(e) => handleSetSelectedCard(data, e)}>
                                                        <Link to="/lab-report">View</Link>
                                                        {/* <span
                                                            className="desktop-view-btn"
                                                            // onClick={handleShowPDFModal}
                                                            onClick={() => {handleSetSelectedCard(data); handleShowPDFModal();}}
                                                        >
                                                            View
                                                        </span> */}
                                                    </span>
                                                </div>
                                                {/* View button for desktop */}
                                                <div className='d-none d-lg-block'>
                                                    <span
                                                        className="desktop-view-btn"
                                                        onClick={(e) => handleSetSelectedCard(data, e)}
                                                    >
                                                        View
                                                    </span>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Card>
                                )
                            }
                        </Col>
                    }
                    {gettingCardData &&
                        <Col className='card-list' xs={12} s={12} md={12} lg={12} xl={12}>
                            <div style={{ textAlign: "center", marginTop: "30px" }}>
                                <span style={{ fontSize: "2vh", fontFamily: "sans-serif" }}>Getting Reports </span>
                                <Spinner animation="grow" style={{ height: "10px", width: "10px", marginBottom: "2px" }} />
                                <Spinner animation="grow" style={{ height: "10px", width: "10px", marginBottom: "2px" }} />
                                <Spinner animation="grow" style={{ height: "10px", width: "10px", marginBottom: "2px" }} />
                            </div>
                        </Col>
                    }
                </Col>

                <Col xs={0} sm={0} md={0} lg={7} xl={9} className='pdf-quick-view'>
                    <div className='d-none d-lg-block'>
                        <PdfViewer
                            // printRef={printRef}
                            pdfsToEmail={pdfsToEmail}
                            removePdfs={removePdfs}
                            selectedCard={selectedCard}
                            pdfScale={2}
                        />
                    </div>
                </Col>
            </Row>
            <FiltersModal
                customerData={customerData}
                reportsQueryRequests={reportsQueryRequests}
                handleAccount={handleAccount}
                handleSampleType={handleSampleType}
                handleTimeSelect={handleTimeSelect}
                handleSetNewStartDate={handleSetNewStartDate}
                handleSetNewEndtDate={handleSetNewEndtDate}
                handleFilterForProducer={handleFilterForProducer}
                handleFilterForMaterialType={handleFilterForMaterialType}
                selectedMaterialType={advFiltersData.selectedMaterialType}
                selectedProducer={advFiltersData.selectedProducer}
                advFiltersData={advFiltersData}
                showAdvFilters={showAdvFilters}
                handleApplyFilters={handleApplyFilters}
                gettingListData={gettingListData}
                isOpen={show}
                handleClose={handleClose}
            />
        </>
    )
}
export default ReportsArea;