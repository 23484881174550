import { Row, Col, Button } from 'react-bootstrap';
import ServitechLogo from './svg/servitech-logo.svg'

import './styles.css'


export default function TodoComponent() {
    const handleGoBack = () => {
        window.history.back();
    }

    return (
        <div className='header'>
            <Row className='row-header' style={{ padding: "0", margin: "0" }}>
                <Col xs={2} s={2} md={2} lg={2} xl={2}>
                    <img alt="ServiTech" className="logo" src={ServitechLogo} />
                </Col>
                <Col xs={{ span: 2, offset: 10 }} s={{ span: 2, offset: 10 }} md={{ span: 2, offset: 10 }} lg={{ span: 2, offset: 10 }} xl={{ span: 2, offset: 10 }}>
                    <Button onClick={handleGoBack} className='back-link'><b>Back</b></Button>
                </Col>
            </Row>
            <Row className='page-header' style={{ padding: "0", margin: "0", width: "100%", borderBottom: "1.5px solid gray" }}>
                <Col >
                    <div className='d-none d-lg-block'>
                        <p style={{ marginBottom: "0" }}>Lab Reports</p>
                    </div>
                    <div className='d-lg-none'>
                        <p style={{ marginBottom: "0", fontSize: "18pt" }}>Lab Reports</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}


